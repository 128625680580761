export default [
    {
        path: "/:locale?/login",
        name: "login",
        component: () => import("../views/account/login.vue"),
        meta: { authRequired: "NoLogin" },
    },
    {
        path: "/:locale?/register",
        name: "Register",
        component: () => import("../views/account/register.vue"),
        meta: { authRequired: "NoLogin" },
    },
    {
        path: "/:locale?/forgot-password",
        name: "Forgot password",
        component: () => import("../views/account/forgot-password.vue"),
        meta: { authRequired: "NoLogin" },
    },
    {
        path: '/:locale?/reset-password',
        name: 'reset-password',
        meta: { authRequired: "NoLogin" },
        component: () => import('../views/auth/create/reset')
    },
    {
        path: '/:locale?/twostep',
        name: 'twostep-basic',
        meta: { authRequired: "PartialLogin" },
        component: () => import('../views/auth/twostep/basic')
    },
    {
        path: "/:locale?/docutrap-list",
        name: "docutrap-list",
        meta: { authRequired: "FullLogin" },
        component: () => import("../views/apps/projects/doc-list"),
    },
    {
        path: "/:locale?/docutrap-list-archived",
        name: "docutrap-list-archived",
        meta: { authRequired: "FullLogin", abbonamentoRequired: "SiAbbonato", userValuesCheckNotNone: true },
        component: () => import("../views/apps/projects/doc-list-archived"),
    },
    {
        path: "/:locale?/docutrap-log",
        name: "docutrap-log",
        meta: { authRequired: "FullLogin", abbonamentoRequired: "SiAbbonato", documentiQRCodesSuperato: true },
        component: () => import("../views/apps/projects/doc-log"),
    },
    {
        path: "/:locale?/docutrap-log-archived",
        name: "docutrap-log-archived",
        meta: { authRequired: "FullLogin", abbonamentoRequired: "SiAbbonato", archiveSuperato: true, userValuesCheckNotNone: true },
        component: () => import("../views/apps/projects/doc-log-archived"),
    },
    {
        path: "/:locale?/docutrap-alerts",
        name: "docutrap-alerts",
        meta: { authRequired: "FullLogin", abbonamentoRequired: "SiAbbonato", documentiQRCodesSuperato: true },
        component: () => import("../views/apps/projects/doc-alerts"),
    },
    {
        path: "/:locale?/docutrap-selection",
        name: "docutrap-selection",
        meta: { authRequired: "FullLogin" },
        component: () => import("../views/apps/projects/doc-selection"),
    },
    {
        path: "/:locale?/create-docutrap-strutturato",
        name: "doc-strutturato",
        meta: { authRequired: "FullLogin", abbonamentoRequired: "SiAbbonato", documentiDisponibilita: true, userValuesCheckNotNone: true },
        component: () => import("../views/apps/projects/doc-strutturato"),
    },
    {
        path: "/:locale?/create-docutrap-lista",
        name: "projects-docutrap-lista",
        meta: { authRequired: "FullLogin", abbonamentoRequired: "SiAbbonato", documentiDisponibilita: true, userValuesCheckNotNone: true },
        component: () => import("../views/apps/projects/docutrap-lista"),
    },
    {
        path: "/:locale?/create-docutrap-vuoto",
        name: "projects-docutrap-vuoto",
        meta: { authRequired: "FullLogin", abbonamentoRequired: "SiAbbonato", documentiDisponibilita: true },
        component: () => import("../views/apps/projects/docutrap-vuoto"),
    },
    {
        path: "/:locale?/create-qrcode",
        name: "projects-qrcode",
        meta: { authRequired: "FullLogin", abbonamentoRequired: "SiAbbonato", qrcodesDisponibilita: true },
        component: () => import("../views/apps/projects/docutrap-qrcode"),
    },
    {
        path: "/:locale?/create-link",
        name: "projects-link",
        meta: { authRequired: "FullLogin", abbonamentoRequired: "SiAbbonato", qrcodesDisponibilita: true },
        component: () => import("../views/apps/projects/docutrap-link"),
    },
    // {
    //     path: "/upgrade-abbonamento",
    //     name: "upgrade-abbonamento",
    //     meta: { title: `Sezione upgrade abbonamento - ${config.nomeSito}`, authRequired: "FullLogin", abbonamentoRequired: "SiAbbonato" },
    //     component: () => import("../views/apps/projects/upgrade-abbonamento"),
    // },
    {
        path: "/:locale?/acquisto-abbonamento",
        name: "acquisto-abbonamento",
        meta: { authRequired: "FullLogin", },
        // component: () => import("../views/apps/projects/acquisto-abbonamento"),
        component: () => import("../views/apps/projects/nuovo-abbonamento"),
    },
    {
        path: "/:locale?/gagagugu",
        name: "nuovo-abbonamento",
        meta: { authRequired: "FullLogin", },
        component: () => import("../views/apps/projects/acquisto-abbonamento"),
    },
    {
        path: "/:locale?/profilo",
        name: "profilo",
        meta: { authRequired: "FullLogin", },
        component: () => import("../views/apps/projects/profilo"),
    },
    {
        path: "/:locale?/profilo-setup",
        name: "profilo-setup",
        meta: { authRequired: "FullLogin", },
        component: () => import("../views/apps/projects/profilo-setup"),
    },
    {
        path: "/:locale?/suggerimenti",
        name: "suggerimenti",
        meta: { authRequired: "FullLogin", },
        component: () => import("../views/apps/projects/suggerimenti"),
    },
    {
        path: "/:locale?/supporto",
        name: "supporto",
        meta: { authRequired: "FullLogin", },
        component: () => import("../views/apps/projects/supporto"),
    },
    {
        path: '/:locale?/terms-of-service',
        name: 'termsOfService',
        component: () => import('../views/condizoni-utilizzo.vue')
    },
    {
        path: '/:locale?/success-order',
        name: 'success-order',
        component: () => import('../views/success-order.vue'),
    },
    //path non usato
    {
        path: '/:locale?/guida-telegram',
        name: 'guida-telegram',
        component: () => import('../views/guida-telegram.vue')
    },
    {
        path: "/:catchAll(.*)",
        redirect: "/login"
    }
];